/**
 * External dependencies.
 */
import React from 'react';
import { graphql, Link } from 'gatsby';

const Blog = ({ data: { page } }) => (
	<div className="posts">
		{page.edges.map(({ node: post }) => (
			<div
				key={post.id}
				className="post"
			>
				{post?.featuredImage?.node?.mediaItemUrl && (
					<Link
						to={post.uri}
						className="post__image"
					>
						<img
							src={post.featuredImage.node.mediaItemUrl}
							alt={post.title}
						/>
					</Link>
				)}

				<Link
					to={post.uri}
					className="post__title"
				>
					{post.title}
				</Link>

				<div
					className="post__entry"
					dangerouslySetInnerHTML={{ __html: post.excerpt }}
				/>

				<Link
					to={post.uri}
					className="post__link"
				>
					Read more &#8594;
				</Link>
			</div>
		))}
	</div>
);

export const query = graphql`
    query Blog {
        page: allWpPost {
            edges {
                node {
                    id
                    title
                    excerpt
                    uri
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                }
            }
        },
        menu: wpMenu(locations: {eq: GATSBY_HEADER_MENU}) {
            ...PageMenuFragment
        },
        contact: wp {
            ...PageContactFragment
        }
    }
`;

export default Blog;
